<template>
    <div>
        <div class="d-flex align-items-center mb-1">
            <div class="d-flex justify-content-center align-items-center backgroundIcon">
                <feather-icon
                class="text-custom-blue"
                icon="DropletIcon"
                size="20"
                />
            </div>
            <div class="ml-1">
                <div class="customVaccineName">
                    Vacina {{ vaccine.vacina.nome_tecnico }}
                </div>
                <div class="customVaccineQuantity">
                    Qt. sem fomento: {{ campaignVaccine.totalNumberOfDosesWithoutPromotion.toLocaleString('pt-BR') }} | Qt. com fomento: {{ campaignVaccine.totalNumberOfDosesWithPromotion.toLocaleString('pt-BR') }} | Qt. total: {{ campaignVaccine.totalNumberOfDoses.toLocaleString('pt-BR') }}
                </div>
            </div>
        </div>
        <div v-if="vaccine.estimativa && vaccine.estimativa.length > 0">
            <PrecificationCard
                class="mb-1"
                :vaccine="vaccine"
            />
            <DistributionCard 
                :vaccine="vaccine"
                :divisionByRegion="divisionByRegion"
                @triggerModalError="triggerModalError"
            />
        </div>
    </div>
</template>
<script>

import PrecificationCard from './PrecificationCard.vue'
import DistributionCard from './DistributionCard.vue'

export default {
    components: {
        PrecificationCard, DistributionCard
    },

    props: {
        vaccine: {
            type: Object,
            required: true
        },
        divisionByRegion: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            campaignVaccine: {
                totalNumberOfDosesWithoutPromotion: 0,
                totalNumberOfDosesWithPromotion: 0,
                totalNumberOfDoses: 0
            }
        }
    },

    mounted() {
        this.prepareData()
    },

    methods: {
        prepareData(){
            this.campaignVaccine.totalNumberOfDoses = 0;
            if(this.vaccine.estimativa && this.vaccine.estimativa.length > 0){
                for (let i = 0; i < this.vaccine.estimativa.length; i++) {
                    this.prepareTotalNumberOfDoses(i);
                }
            }
        },

        prepareTotalNumberOfDoses(index){
            this.campaignVaccine.totalNumberOfDoses += this.vaccine.estimativa[index].quantidade;
            if(this.vaccine.estimativa[index].incentivo.descricao == "Normal"){
                this.campaignVaccine.totalNumberOfDosesWithoutPromotion = this.vaccine.estimativa[index].quantidade;
            }
            if(this.vaccine.estimativa[index].incentivo.descricao == "Fomento"){
                this.campaignVaccine.totalNumberOfDosesWithPromotion = this.vaccine.estimativa[index].quantidade;
            }
        },

        triggerModalError(){
            this.$emit('update:changeModalPropError', true)
        }
    },

    watch: {
        vaccine(){
            this.prepareData();
        }
    }

};
</script>
<style>
    .collapse-title {
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 22px !important;
        color: #5E5873 !important;
    }
</style>
<style lang="scss" scoped>
  .backgroundIcon{
    border: 1px solid #ffffff;
    border-radius: 5px;
    min-height: 42px;
    min-width: 42px;
    background-color: #E5EEF7
  }
  .customVaccineName{
    font-family: "Montserrat";
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #6E6B7B;
  }
  .customVaccineQuantity{
    font-family: "Montserrat";
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #6E6B7B;
  }
</style>