<template>
    <b-card class="customBorder">
      <div class="title">
        <span>Adesão por categoria</span>
      </div>
      <div>
        <b-row>
          <b-col 
            sm="4" 
            cols="12"
            v-for="information in data"
            :key="information.id"
          >
            <div class="d-flex align-items-center responsiveInformations">
              <div class="d-flex justify-content-center align-items-center backgroundIcons">
                <feather-icon
                  class="text-custom-blue"
                  :icon="information.icon"
                  size="24"
                />
              </div>
              <div class="ml-1">
                <div class="customNumber">
                  {{ information.number.toLocaleString('pt-BR') }}
                </div>
                <div class="subtitleNumber">
                  {{ information.subtitle }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </template>
  <script>
  import { BCard, BCol, BRow } from 'bootstrap-vue'
  
  export default {
      components: {
        BCard, BCol, BRow
      },
  
      props: {
          adhesionsPerCategory: {
              type: Array,
              required: true
          }
      },
  
      data() {
        return {
          data: [
            {
              number: 0,
              subtitle: 'Trabalhador',
              icon: 'UserIcon'
            },
            {
              number: 0,
              subtitle: 'Dependente',
              icon: 'UsersIcon'
            },
            {
              number: 0,
              subtitle: 'Terceiro',
              icon: 'UserCheckIcon'
            }
          ]
        }
      },

      mounted(){
        this.prepareAdhesionPerCategory();
      },

      methods: {
        prepareAdhesionPerCategory(){
          if(this.$props.adhesionsPerCategory.length > 0){
            for (let i = 0; i < this.$props.adhesionsPerCategory.length; i++) {
              for (let j = 0; j < this.data.length; j++) {
                if(this.data[j].subtitle == this.$props.adhesionsPerCategory[i].descricao){
                  this.data[j].number = this.$props.adhesionsPerCategory[i].quantidade;
                }
              }
            }
          }

        }
      }
  };
  </script>
  <style lang="scss" scoped>
    .customBorder{
        border: 1px solid #D8D6DE;
        border-radius: 5px;
        max-height: 140px;
        height: 140px;
      }
    .title{
        font-family: "Montserrat";
        font-style: normal;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: #5E5873;
        margin-bottom: 25px;
    }
    .backgroundIcons{
      border: 1px solid #ffffff;
      border-radius: 26px;
      min-height: 48px;
      min-width: 48px;
      background-color: #E5EEF7
    }
  
    .customNumber{
      font-family: "Montserrat";
      font-style: normal;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #5E5873;
    }
    .subtitleNumber{
      font-family: "Montserrat";
      font-style: normal;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #6E6B7B;
    }
  
    @media only screen and (max-width: 575px) {
      .customBorder{
        max-height: 250px;
        height: 250px;
      }
      .responsiveInformations{
        padding-left: 30%;
        margin-bottom: 10px;
        align-items: center;
      }
      .title{
        text-align:center;
      }
    }
  </style>