<template>
    <b-modal
        id="visualize-unity-information"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <b-overlay :show="loading" rounded="lg" opacity="0.5" class="w-100 px-2">
            <div class="mt-2 mb-2">
                <div class="modalTitle mb-1">
                    {{ regionName }}
                </div>
                <div
                    v-for="unity in unityData"
                    :key="unity.id"
                >
                    <div v-if="unity.id_unidade == 0" class="mb-1">
                        <div class="modalSubtitle">
                            {{ unity.descricao_unidade }}
                        </div>
                        <div class="modalQuantity">
                            Qt. sem fomento: {{ unity.quantidadeNormal.toLocaleString('pt-BR') }} | Qt. com fomento: {{ unity.quantidadeFomento.toLocaleString('pt-BR') }} | Qt. total: {{ unity.quantidadeTotal.toLocaleString('pt-BR') }}
                        </div>
                    </div>
                    <div v-else class="mb-1">
                        <div class="modalSubtitle">
                            {{ unity.descricao_unidade }}
                        </div>
                        <div class="modalQuantity my-1">
                            <span class="quantitySpecification">Distribuídas:</span><br> Qt. sem fomento: {{ unity.quantidadeNormal.toLocaleString('pt-BR') }} | Qt. com fomento: {{ unity.quantidadeFomento.toLocaleString('pt-BR') }} | Qt. total: {{ unity.quantidadeTotal.toLocaleString('pt-BR') }}
                        </div>
                        <div class="modalQuantity mb-1">
                            <span class="quantitySpecification">Aderidas:</span><br> Qt. sem fomento: {{ unity.dosesAderidasNormal.toLocaleString('pt-BR') }} | Qt. com fomento: {{ unity.dosesAderidasFomento.toLocaleString('pt-BR') }} | Qt. total: {{ unity.dosesAderidasTotal.toLocaleString('pt-BR') }}
                        </div>
                        <div class="modalQuantity mb-1">
                            <span class="quantitySpecification">Saldo:</span><br> Qt. sem fomento: {{ unity.saldoNormal.toLocaleString('pt-BR') }} | Qt. com fomento: {{ unity.saldoFomento.toLocaleString('pt-BR') }} | Qt. total: {{ unity.saldoTotal.toLocaleString('pt-BR') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-center-center mt-2 mb-2">
                <b-button class="buttonBackgroundColor" variant="primary-button" @click="confirmAction">
                    Ok
                </b-button>
            </div>
         </b-overlay>
    </b-modal>
</template>

<script>
import { BModal, BButton, BOverlay } from 'bootstrap-vue';
export default {
    components: {
        BModal, BButton, BOverlay
    },

    props: {
        modalShow: {
            type: Boolean,
            required: true
        },
        regionName: {
            type: String,
            required: false
        },
        unityData: {
            type: Array,
            required: false
        },
        loading: {
            type: Boolean,
            required: true
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();
            this.$emit('confirmed', modalEvent);
        }
    }
}
</script>
<style>
    .buttonBackgroundColor{
        background-color: #2772C0;
        border-color: #2772C0;
    }
    .modalTitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #5E5873;
    }
    .modalSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #6E6B7B;
    }
    .modalQuantity{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #6E6B7B;
    }
    .quantitySpecification{
        font-weight: 500;
        color: #8a869b;
    }
    @media (min-width: 576px){
    .modal-dialog {
        max-width: 530px!important;
    }
    }
</style>

