<template>
    <div>
        <b-tabs 
            class="vaccine"
            :vertical="responsiveTabs" 
            v-model="tabIndexComponent"
        >
            <b-tab 
                v-for="vaccine in vaccines" 
                :key="vaccine.id"
            >
                <template #title>
                    <feather-icon
                        icon="DropletIcon"
                        size="18"
                    />
                    {{ vaccine.vacina.nome_tecnico }}
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'

export default {
    components: {
        BCard, BTabs, BTab
    },

    props: {
        tabIndex: {
            type: Number,
            required: true
        },
        vaccines: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            tabIndexComponent: this.$props.tabIndex,
            responsiveTabs: this.responsiveWidthChange(),
        }
    },

    mounted(){
        window.addEventListener('resize', () => {
            this.responsiveTabs = this.responsiveWidthChange();
        });
    },

    methods: {
        responsiveWidthChange(){
            if(window.innerWidth < 992){
                return false;
            }else{
                return true;
            }
        }
    },

    watch: {
        tabIndexComponent: {
            handler(newValue) {
                this.$emit('update:changeTab', newValue)
            }
        }
    }

};
</script>
<style>
    .nav-tabs .nav-link.active {
        color: #2772C0;
    }

    .vaccine .nav-tabs .nav-link:after {
        width: 2.5px;
        height: 100%;
        border-radius: 2.5px;
        background: #2772C0 !important;
    }

    .nav-pills .nav-link, .nav-tabs .nav-link {
        justify-content: initial;
    }

    @media only screen and (max-width: 991px) {
        .vaccine .nav-tabs .nav-link:after {
            width: 100%;
            height: 3px;
            border-radius: 2.5px;
        }
    }
</style>