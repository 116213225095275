<template>
    <app-collapse 
        class="shadow bg-white rounded"
        v-if="precification.length > 0"
    >
        <app-collapse-item
            title="Precificação"
        >
            <div 
                v-for="prices in precification"
                :key="prices.id"
                class="mb-1"
            >
                <div
                    class="typeOfCompany"
                >
                    {{ prices.typeCompanyPriceName }}
                </div>
                <div class="d-flex flex-row">
                    <div 
                        v-for="categories in prices.categories"
                        :key="categories.id"
                        class="pr-3 responsiveSM"
                    >
                        <div
                            v-for="promotionDescriptionAndValue in categories.values"
                            :key="promotionDescriptionAndValue.id"
                        >  
                            <div class="descriptionAndValue" v-if="promotionDescriptionAndValue.promotion == 'Normal'">
                                {{ promotionDescriptionAndValue.description }}:  {{ formaterPrice(promotionDescriptionAndValue.value) }}
                            </div>
                            <div class="descriptionAndValue" v-else-if="promotionDescriptionAndValue.promotion == 'Fomento' && categories.values.length == 1">
                                {{ promotionDescriptionAndValue.description }} com fomento:  {{ formaterPrice(promotionDescriptionAndValue.value) }}
                            </div>
                            <div class="descriptionAndValue" v-else>
                                Com fomento:  {{ formaterPrice(promotionDescriptionAndValue.value) }} 
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </app-collapse-item>
    </app-collapse>
</template>
<script>
    
import { BCol, BRow } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';

export default {
    components: {
        BCol, BRow,
        AppCollapse, AppCollapseItem
    },

    props: {
        vaccine: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
                precification: []
            }
    },

    mounted() {
        this.prepareData()
    },

    methods: {
        prepareData(){
            this.precification = [];
            for (let i = 0; i < this.vaccine.estimativa.length; i++) {
                this.preparePrecification(i);
            }
        },

        preparePrecification(index){
            let campaignPrice = this.vaccine.estimativa[index].campanha_tipo_preco;
            if(campaignPrice && campaignPrice.length > 0){
                let promotion = this.vaccine.estimativa[index].incentivo.descricao;
                for (const element of campaignPrice) {
                    if(this.precification.length > 0){
                        this.findPrecificationCategoryInList(element, promotion);
                    }else if(this.precification.length == 0){
                        this.addPrecificationToList(element, promotion);
                    }
                }
            }
        },

        addPrecificationToList(campaignTypePrice, promotion){
            this.precification.push(
                    {
                        typePriceId: campaignTypePrice.tipo_preco.id_tipo_preco,
                        typeCompanyPriceName: campaignTypePrice.tipo_preco.descricao,
                        categories: []
                    }
                );
            this.findPrecificationCategoryInList(campaignTypePrice, promotion)
        },

        findPrecificationCategoryInList(campaignTypePrice, promotion){
            const precification = this.precification.find(p => p.typePriceId && p.typePriceId == campaignTypePrice.id_tipo_preco);
            if(precification){
                this.findPrecificationCategoryToList(campaignTypePrice, precification.categories, promotion)
            }else{
                this.addPrecificationToList(campaignTypePrice, promotion);
            }
        },

        findPrecificationCategoryToList(campaignTypePrice, categories, promotion){
            let campaignCategoryTypePrice = campaignTypePrice.campanha_tipo_preco_categoria;
            campaignCategoryTypePrice.sort((a, b) => a.id_categoria - b.id_categoria);
            for (const element of campaignCategoryTypePrice) {
                const typeCategoryPrecification = categories.find(category => category.categoryId && category.categoryId == element.id_categoria);
                if(typeCategoryPrecification){
                    this.addPrecificationWithoutCategoryToList(promotion, element, typeCategoryPrecification);
                }else{
                    this.addPrecificationWithCategoryToList(promotion, categories, element);
                }
            }
        },

        addPrecificationWithoutCategoryToList(promotion, campaignCategoryTypePriceUnique, typeCategoryPrecification){
            if(promotion == 'Normal'){
                typeCategoryPrecification.values.unshift(
                    {
                        promotion: promotion,
                        description: campaignCategoryTypePriceUnique.categoria.descricao,
                        value: campaignCategoryTypePriceUnique.valor_dose
                    }
                )
            }else{
                typeCategoryPrecification.values.push(
                    {
                        promotion: promotion,
                        description: campaignCategoryTypePriceUnique.categoria.descricao,
                        value: campaignCategoryTypePriceUnique.valor_dose
                    }
                )
            }
        },

        addPrecificationWithCategoryToList(promotion, categories, campaignCategoryTypePriceUnique){
            categories.push(
                {
                    categoryId: campaignCategoryTypePriceUnique.id_categoria,
                    values: [
                        {
                            promotion: promotion,
                            description: campaignCategoryTypePriceUnique.categoria.descricao,
                            value: campaignCategoryTypePriceUnique.valor_dose
                        }
                    ]
                }
            )
        },
        formaterPrice(price){
            const formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                 currency: 'BRL' 
            });

            return formatter.format(price)
        }
    },

    watch: {
        vaccine(){
            this.prepareData();
        }
    }
    
};
</script>
<style>
    .typeOfCompany{
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #5E5873;
        margin-bottom: 5px;
    }
    .descriptionAndValue{
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #5E5873;
    }

    @media only screen and (max-width: 575px) {
        .flex-row {
            flex-direction: column !important;
        }
        .responsiveSM {
            margin-bottom: 5px;
        }
    }
</style>