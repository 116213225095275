<template>
  <b-card class="customBorder">
    <div class="title">
      <span>Progresso</span>
    </div>
    <div>
      <b-row>
        <b-col 
          sm="4" 
          cols="12"
          v-for="information in data"
          :key="information.id"
        >
          <div class="d-flex align-items-center responsiveInformations">
            <div class="d-flex justify-content-center align-items-center backgroundIcons">
              <feather-icon
                class="text-custom-blue"
                :icon="information.icon"
                size="24"
              />
            </div>
            <div class="ml-1">
              <div class="customNumber">
                {{ information.number.toLocaleString('pt-BR') }}
              </div>
              <div class="subtitleNumber">
                {{ information.subtitle }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="4" cols="12">
          <div class="d-flex align-items-center responsiveInformations">
            <div class="d-flex justify-content-center align-items-center backgroundIcons">
              <img class="text-custom-blue" :src="VaccineIcon" alt="vaccineIcon" width="24" height="24" />
            </div>
            <div class="ml-1">
              <div class="customNumber">
                {{ campaignVaccinated.toLocaleString('pt-BR') }}
              </div>
              <div class="subtitleNumber">
                Vacinados
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import VaccineIcon from "@/assets/custom-icons/vaccine.svg";

export default {
    components: {
      BCard, BCol, BRow
    },

    props: {
        campaignVaccinesGoal: {
            type: Number,
            required: true
        },
        campaignAdheredDoses: {
            type: Number,
            required: true
        },
        campaignVaccinated: {
            type: Number,
            required: true
        }
    },

    data() {
      return {
        data: [
          {
            number: this.$props.campaignVaccinesGoal,
            subtitle: 'Meta',
            icon: 'CalendarIcon'
          },
          {
            number: this.$props.campaignAdheredDoses,
            subtitle: 'Aderido',
            icon: 'CheckIcon'
          }
        ]
      }
    },

    setup() {
      return {
        VaccineIcon
      };
    }
};
</script>
<style lang="scss" scoped>
  .customBorder{
      border: 1px solid #D8D6DE;
      border-radius: 5px;
      max-height: 140px;
      height: 140px;
    }
  .title{
      font-family: "Montserrat";
      font-style: normal;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      color: #5E5873;
      margin-bottom: 25px;
  }
  .backgroundIcons{
    border: 1px solid #ffffff;
    border-radius: 26px;
    min-height: 48px;
    min-width: 48px;
    background-color: #E5EEF7
  }

  .customNumber{
    font-family: "Montserrat";
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #5E5873;
  }
  .subtitleNumber{
    font-family: "Montserrat";
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #6E6B7B;
  }

  @media only screen and (max-width: 575px) {
    .customBorder{
      max-height: 250px;
      height: 250px;
    }
    .responsiveInformations{
      padding-left: 30%;
      margin-bottom: 10px;
      align-items: center;
    }
    .title{
      text-align:center;
    }
  }
</style>