<template>
    <div class="pb-4">
        <PageHeader :screenName="linkItems[2].name" :linkItems="linkItems"/>
        <ErrorModal
            :modalShow="modalError.showModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirectList"
        />
        <b-overlay :show="loadingData" rounded="lg" opacity="0" class="w-100 mt-1">
            <b-card v-if="!loadingData" class="mt-n1">
                <header class="mb-1">
                    <div class="mb-1">
                        <SituationBadge :situation="campaign.campaignSituation" />
                    </div>
                    <div class="mb-1">
                        <span class="title">
                            {{ campaign.campaignTimeline.campaignYear+linkItems[2].name }}
                        </span>
                    </div>
                    <div>
                        <span class="subtitle">
                            {{ campaign.campaignDisease+" | "+campaign.campaignVaccinesGoal+" doses disponibilizadas" }}
                        </span>
                    </div>
                    <div class="mt-1">
                        <a :href="campaign.urlContract" class="hiperlink" target="_blank">Termo de Adesão</a>
                    </div>
                </header>
                <main>
                    <b-row>
                        <b-col lg="4">
                            <TimelineCard 
                                :campaignTimeline="campaign.campaignTimeline.timeline"
                            />
                        </b-col>
                        <b-col xl="6" lg="7">
                            <ProgressCard 
                                :campaignVaccinesGoal="campaign.campaignVaccinesGoal"
                                :campaignAdheredDoses="campaign.campaignAdheredDoses"
                                :campaignVaccinated="campaign.campaignVaccinated"
                            />
                            <AdhesionCard
                                :adhesionsPerCategory="campaign.adhesionPerCategory"
                            />
                        </b-col>
                    </b-row>
                    <div class="minorTitle mb-1">
                        Vacina
                    </div>
                    <b-row v-if="campaign.campaignBruteVaccinesData">
                        <b-col lg="3" cols="12">
                            <VaccineTab
                                :tabIndex="tabIndex"
                                :vaccines="campaign.campaignBruteVaccinesData"
                                :changeTab.sync="tabIndex"
                            />
                        </b-col>
                        <b-col lg="9" cols="12">
                            <InfoCampaignCard
                                :vaccine="campaign.campaignBruteVaccinesData[tabIndex]"
                                :divisionByRegion="campaign.campaignDepartamentDivisionByRegion"
                                :changeModalPropError.sync="modalError.showModal"
                            />
                        </b-col>
                    </b-row>
                </main>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

import { BCard, BCol, BRow, BOverlay } from 'bootstrap-vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import SituationBadge from '@/views/components/custom/SituationCampagin/SituationBadges.vue';
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import { getIdCampaignEditAndShow } from '@core/utils/store/getStore';
import { setIdCampaignEditAndShow } from '@core/utils/store/setStore';
import moment from 'moment';
import TimelineCard from './ShowCampaignComponents/TimelineCard.vue'
import ProgressCard from './ShowCampaignComponents/ProgressCard.vue'
import AdhesionCard from './ShowCampaignComponents/AdhesionCard.vue'
import VaccineTab from './ShowCampaignComponents/VaccineTab.vue'
import InfoCampaignCard from './ShowCampaignComponents/InfoCampaignCard.vue'

export default {
    title: 'Visualizar campanha',

    components: {
        PageHeader,
        SituationBadge,
        ErrorModal,
        BCard, BCol, BRow, BOverlay,
        TimelineCard, ProgressCard, AdhesionCard, VaccineTab, InfoCampaignCard
    },

    data() {
        return {
            modalError: {
                showModal: false,
                caseModal: 'idInexistente'
            },
            linkItems: [
                {
                    name: 'Campanhas',
                    routeName: 'campanha-list'
                },
                {
                    name: 'Ver campanhas',
                    routeName: 'campanha-list'
                    
                },
                {
                    name: 'Carregando dados...',
                    active: true
                }
            ],
            loadingData: true,
            campaign: {
                campaignBruteVaccinesData: null,
                campaignSituation: null,
                campaignDepartamentDivisionByRegion: true,
                campaignTimeline:{
                    campaignYear: '',
                    timeline: []
                },
                campaignDisease: '',
                campaignVaccinesGoal: 0,
                campaignAdheredDoses: 0,
                campaignVaccinated: 0,
                adhesionPerCategory: [],
                adhesionIdAttachment: null,
                urlContract: null
            },
            tabIndex: 0
        }
    },

    mounted() {
        this.initiateVisualization()
    },

    methods: {
        async initiateVisualization(){
            this.verifyIdCampaignVuex();
            this.setIdCampaignVuex();
            await this.loadCampaignData();
            await this.setUrlBlobContract();
            this.loadingData = false;
        },

        verifyIdCampaignVuex(){
            if((getIdCampaignEditAndShow() === null || typeof getIdCampaignEditAndShow() == 'undefined') && typeof this.$route.params.idCampaign == "undefined") {
                this.modalError.showModal = true;
                return;
            }
        },

        setIdCampaignVuex(){
            if(typeof this.$route.params.idCampaign != "undefined"){
                if(this.$route.params.idCampaign !== getIdCampaignEditAndShow()) {
                    setIdCampaignEditAndShow(this.$route.params.idCampaign);
                }
            }
        },

        async loadCampaignData(){
            const parameterizedRoute = this.$api.getShowAndEditCampanha(getIdCampaignEditAndShow());
            await this.$http.get(parameterizedRoute, {
                params: { 
                    visualizacao: true
                }
            }).then(({ data }) => {
                this.fillHeader(data.campanha);
                this.campaign.campaignDepartamentDivisionByRegion = data.campanha.departamento.divisao_por_regiao;
                this.campaign.campaignAdheredDoses = data.dosesAderidas;
                this.campaign.campaignVaccinated = data.aplicacoesVacinas;
                this.campaign.campaignBruteVaccinesData = data.campanha.campanha_vacina;
                this.campaign.campaignTimeline.timeline = data.campanha.campanha_cronograma ? data.campanha.campanha_cronograma : [];
                this.campaign.adhesionPerCategory = data.adesoesPorCategoria;
                this.campaign.adhesionIdAttachment = data.campanha.anexo[0]?.id_anexo;
            }).catch((response) => {
                this.modalError.showModal = true;
            })
        },

        fillHeader(campaignLoadedData){
            this.campaign.campaignSituation = campaignLoadedData.situacao;
            this.linkItems[2].name = campaignLoadedData.descricao;
            if(campaignLoadedData.campanha_cronograma.length > 0){
                this.campaign.campaignTimeline.campaignYear = moment(campaignLoadedData.campanha_cronograma[0].inicio).format('YYYY')+" - ";
            }
            this.campaign.campaignDisease = campaignLoadedData.doenca.descricao;
            this.prepareVaccinesGoal(campaignLoadedData.campanha_vacina);
        },

        prepareVaccinesGoal(campaignVaccines){
            for (let i = 0; i < campaignVaccines.length; i++) {
                if(campaignVaccines[i].estimativa){
                    for (let j = 0; j < campaignVaccines[i].estimativa.length; j++) {
                        this.campaign.campaignVaccinesGoal += campaignVaccines[i].estimativa[j].quantidade;
                    }
                }
            }
        },

        redirectList() {
            this.$router.push({ name: 'campanha-list' });
        },

        async setUrlBlobContract() {
            await this.$http
            .get(this.$api.getContract(getIdCampaignEditAndShow()), {
                responseType: "blob",
                headers: {
                    'Cache-Control': 'no-cache',
                }

            })
            .then((response) => {

                const blob = new Blob([response.data], { type: "application/pdf" });
                this.campaign.urlContract = URL.createObjectURL(blob);
            })
            .catch((error) => {
  
                let infoText = null
                if(error.response.status == 404){
                    infoText = 'O Termo de Adesão não foi criado'
                }
                this.$helpers.modalErroTermoDeAdesao(infoText);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
    .title{
        font-family: "Montserrat";
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        color: #5E5873;
    }
    .subtitle{
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #5E5873;
    }

    .minorTitle{
        font-family: "Montserrat";
        font-style: normal;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: #5E5873;
    }

    .hiperlink{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #2772C0;
    }
</style>