<template>
    <section class="d-inline">
        <b-badge v-if="situationCampaign === 'Rascunho'" variant="light-info">{{ situationCampaign }}</b-badge>
        <b-badge v-if="situationCampaign === 'Agendada'" variant="light-primary">{{ situationCampaign }} </b-badge>
        <b-badge v-if="situationCampaign === 'Em andamento'" variant="light-warning">{{ situationCampaign }}</b-badge>
        <b-badge v-if="situationCampaign === 'Cancelada'" variant="light-danger">{{ situationCampaign }}</b-badge>
        <b-badge v-if="situationCampaign === 'Concluída'" variant="light-success">{{ situationCampaign }}</b-badge>
    </section>
</template>

<script>
import { BBadge } from 'bootstrap-vue';

import { busEvent } from '@/main';

export default {
    components: {
        BBadge
    },

    props: {
        situation: {
            type: String,
        }
    },

    data() {
        return {
            situationCampaign: this.$props.situation ? this.$props.situation : 'Rascunho'
        }
    },

    mounted() {
        busEvent.$on('situationCampaign', (situation) => {
            this.changeSituation(situation);
        });
    },

    methods: {
        changeSituation(situation) {
            if(situation !== 'Rascunho') {
                this.situationCampaign = situation;
            }
        }
    },

    watch: {
        situation(value) {
            this.changeSituation(value);
        }
    }
}
</script>

<style>

</style>