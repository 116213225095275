<template>
    <app-collapse class="shadow bg-white rounded">
        <app-collapse-item
            title="Distribuição"
        >
            <VisualizeUnityInformationModal
                :modalShow="showModal"
                :regionName="modalRegionName"
                :unityData="modalData"
                :loading="loadingModal"
                @confirmed="closeModal"
            />
            <TableFilter
                :filter="filter"
                @filterTable="loadDataCampaignRegion"
            />
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <section v-if="!loadingData">
                <b-table
                    id="tableDoenca"
                    responsive
                    :stickyColumn="true"
                    :busy.sync="filtering"
                    :fields="divisionByRegion ? table.fieldsDivisionByRegion :table.fieldsNoDivisionByRegion "
                    :items="table.items"
                    no-local-sorting
                    :no-sort-reset="true"
                >
                    <template #cell(action)="row" v-if="divisionByRegion">
                        <feather-icon
                            v-if="row.item.id_regiao === 0"
                            :id="'show-campaign-unity'+row.item.id_regiao"
                            role="button"
                            class="text-custom-blue"
                            icon="InfoIcon"
                            size="18"
                            @click="openModalReservationInformation(row.item)"
                        />

                        <feather-icon
                            v-else
                            :id="'show-campaign-unity'+row.item.id_regiao"
                            role="button"
                            class="text-custom-blue"
                            icon="EyeIcon"
                            size="18"
                            @click="openModalUnityInformation(row.item)"
                        />
                    </template>
                    <template #cell(descricao)="row">
                        <div class="typographyRegion">
                            <span v-if="divisionByRegion">
                                {{ row.item.descricao }}
                            </span>
                            <span v-else>
                                {{ row.item.descricao_unidade }}
                            </span>
                        </div>
                    </template>
                    <template #cell(quantidadeNormal)="row">
                        <span class="backgroundQuantity quantity">
                            {{ row.item.quantidadeNormal.toLocaleString('pt-BR') }}
                        </span>
                    </template>
                    <template #cell(quantidadeFomento)="row">
                        <span class="backgroundQuantity quantity">
                            {{ row.item.quantidadeFomento.toLocaleString('pt-BR') }}
                        </span>
                    </template>
                    <template #cell(quantidadeTotal)="row">
                        <span class="quantity">
                            {{ row.item.quantidadeTotal.toLocaleString('pt-BR') }}
                        </span>
                    </template>
                </b-table>
                <div class="pb-2 px-1">
                    <span v-if="withoutCampaign" class="ml-1">Nenhum resultado encontrado</span>
                    <b-col sm="12" class="mt-2">
                        <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
                    </b-col>
                </div>
            </section>
        </app-collapse-item>
    </app-collapse>
</template>
<script>
import { BCol, BTable } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import VisualizeUnityInformationModal from './VisualizeUnityInformationModal.vue'

export default {
    components: {
        BCol, BTable,
        AppCollapse, AppCollapseItem,
        TableFilter,
        VuePerfectScrollbar,
        CustomPagination,
        VisualizeUnityInformationModal
    },

    props: {
        vaccine: {
            type: Object,
            required: true
        },
        divisionByRegion: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            filter: false,
            loadingData: true,
            filtering: false,
            table: {
                fieldsDivisionByRegion: [
                    {key: "action", label: ""},
                    {key: "descricao", label: "REGIÃO | UNIDADE", class: 'text-left'},
                    {key: "quantidadeNormal", label: "SEM FOMENTO", class: 'text-center blockOverflow'},
                    {key: "quantidadeFomento", label: "COM FOMENTO", class: 'text-center blockOverflow'},
                    {key: "quantidadeTotal", label: "TOTAL", class: 'text-center'}
                ],
                fieldsNoDivisionByRegion: [
                    {key: "descricao", label: "REGIÃO | UNIDADE", class: 'text-left'},
                    {key: "quantidadeNormal", label: "SEM FOMENTO", class: 'text-center blockOverflow'},
                    {key: "quantidadeFomento", label: "COM FOMENTO", class: 'text-center blockOverflow'},
                    {key: "quantidadeTotal", label: "TOTAL", class: 'text-center'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100]
            },
            lengthNomeTecnicoMaximo: 28,
            withoutCampaign: false,
            paginationData: {
                currentPage: 0,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10
            },
            showModal: false,
            modalData: null,
            loadingModal: false,
            modalRegionName: 'Carregando dados...'
        }
    },

    methods: {
        loadDataCampaignRegion(filterData = null){
            this.paginationData.defaultSize = filterData.defaultSize ?? 10;

            const parameters = {
                termoPesquisa: filterData ? filterData.searchTerm : '',
                tamanho: this.paginationData.defaultSize,
                pagina: filterData.currentPage ?? this.paginationData.currentPage,
                ativo: this.$props.divisionByRegion
            };

            this.filtering = true;

            this.$http.get(this.$api.campanhaVisualizacaoListagem(
                this.$props.vaccine.id_campanha,
                this.$props.vaccine.id_vacina
            ), { params: parameters }).then(({ data }) => {
                this.table.items = data.data;

                this.withoutCampaign = false;
                if(data.data.length == 0) {
                    this.withoutCampaign = true;
                }

                this.loadingData = false;

                this.filtering = false;
                this.startPagination(data)
            }).catch(() => {
                this.$emit('triggerModalError')
            })
        },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.filtering = true;
            this.filter = !this.filter;
        },

        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },

        openModalUnityInformation(unityInformation){
            this.modalRegionName = 'Carregando dados...';
            this.modalData = null;
            this.showModal = true;
            this.loadingModal = true;
            this.$http.get(this.$api.campanhaVacinasUnidadesVisualizacao(
                this.$props.vaccine.id_campanha,
                this.$props.vaccine.id_vacina,
                unityInformation.id_regiao
            )).then(({ data }) => {
                this.modalRegionName = unityInformation.descricao
                this.modalData = data;
                this.loadingModal = false;
            }).catch(() => {
                this.$emit('triggerModalError')
            })
        },
        openModalReservationInformation(reservation){
            this.showModal = true;
            this.modalRegionName = reservation.descricao
            this.modalData = [reservation];
        },
        
        closeModal(){
            this.showModal = false;
        }
    },

    watch: {
        vaccine(){
            this.loadingData = true;
            this.loadDataCampaignRegion();
        }
    }
};
</script>
<style>
    .typographyRegion{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #5E5873;
    }
    .customizeDifferentSpace{
        width: 100px;
    }
    .backgroundQuantity{
        padding: 5px 10px;
        background-color: #F8F8F8;
    }
    .blockOverflow{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .quantity{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #5E5873;
    }
    .justify-content-between {
        justify-content: initial !important;
    }
</style>