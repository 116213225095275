<template>
  <b-card class="customBorder">
    <div class="title">
      <span>Andamento</span>
    </div>
    <div>
      <app-timeline>
        <app-timeline-item
          title="Período de Adesão"
          :subtitle="adhesionPeriod.data"
          :variant="adhesionPeriod.afterToday ? 'success' : 'info'"
        />
        <app-timeline-item
          title="Aplicação de Doses"
          :subtitle="applicationPeriod.data"
          :variant="applicationPeriod.afterToday ? 'success' : 'info'"
        />
        <app-timeline-item
          title="Conclusão da Campanha"
          :subtitle="conclusionPeriod.data"
          :variant="conclusionPeriod.afterToday ? 'success' : 'info'"
        />
      </app-timeline> 
    </div>
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment';

export default {
    components: {
        AppTimeline,
        AppTimelineItem,
        BCard
    },

    props: {
      campaignTimeline: {
          type: Array,
          required: true
      }
    },

    data() {
      return {
        adhesionPeriod: {
          data: '--/--/----',
          afterToday: false
        },
        applicationPeriod: {
          data: '--/--/----',
          afterToday: false
        },
        conclusionPeriod: {
          data: '--/--/----',
          afterToday: false
        }
      }
    },

    mounted() {
      this.fillTimeLine()
    },

    methods: {
      fillTimeLine(){
        if(this.campaignTimeline.length > 0){
          this.adhesionPeriod.data = moment(this.campaignTimeline[0].inicio).format('DD/MM/YYYY')+' - '+moment(this.campaignTimeline[0].termino).format('DD/MM/YYYY');
          this.adhesionPeriod.afterToday = moment() > moment(this.campaignTimeline[0].inicio) ? true : false ;
          this.applicationPeriod.data = moment(this.campaignTimeline[1].inicio).format('DD/MM/YYYY')+' - '+moment(this.campaignTimeline[1].termino).format('DD/MM/YYYY');
          this.applicationPeriod.afterToday = moment() > moment(this.campaignTimeline[1].inicio) ? true : false ;
          this.conclusionPeriod.data = moment(this.campaignTimeline[2].inicio).format('DD/MM/YYYY')+' - '+moment(this.campaignTimeline[2].termino).format('DD/MM/YYYY');
          this.conclusionPeriod.afterToday = moment() > moment(this.campaignTimeline[2].termino) ? true : false ;
        }
      }
    }
};
</script>
<style>
  .timeline-variant-success .timeline-item-point {
      background-color: #2772C0 !important;
  }
  .timeline-variant-info .timeline-item-point {
      background-color: #B9B9C3 !important;
  }
</style>
<style lang="scss" scoped>
  .customBorder{
    border: 1px solid #D8D6DE;
    border-radius: 5px;
    max-height: 310px;
    height: 310px;
  }
  .title{
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #5E5873;
    margin-left: 6px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 991px) {
    .customBorder {
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }

  @media only screen and (max-width: 575px) {
    .customBorder {
      max-height: 340px;
      height: 340px;
      width: 100%;
    }
  }
</style>